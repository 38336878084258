* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #2B2A29;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  height: 10px;
  background: var(--primary-color);
  border-radius: 100px;
}

body {
  font-family: "Raleway", sans-serif;
}

button[data-toggle-navbar][data-is-open="true"] #line-1 {
  transform: translateY(0.375rem) rotate(40deg);
}

button[data-toggle-navbar][data-is-open="true"] #line-2 {
  transform: scaleX(0);
  opacity: 0;
}

button[data-toggle-navbar][data-is-open="true"] #line-3 {
  transform: translateY(-0.375rem) rotate(-40deg);
}

.login_img_section {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url('./Assets/contactbg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}